export const headerItems = [
    {
        sort_by: 'index',
        styles: 'width: 10%; text-align: left'
    },
    {
        sort_by: 'category_name',
        title: 'Category name',
        styles: 'width: 20%; display: flex; justify-content: flex-start; text-align: left'
    },
    {
        sort_by: 'subcategories',
        title: 'Subcategories',
        styles: 'width: 50%; display: flex; justify-content: flex-start;'
    },
    {
        sort_by: 'post_count',
        title: 'Post count',
        styles: 'width: 10%; text-align: center'
    },
    {
        sort_by: 'actions',
        // title: 'Actions',
        styles: 'width: 10%',
        hide_sort: true
    }
]
